import React from "react"
import Helmet from "react-helmet"

import "typeface-ubuntu"
import "./all.sass"
import { Link } from "gatsby"
import logo from "../img/logo.svg"

const TemplateWrapper = ({ children }) => (
  <>
    <Helmet title="Harris County Municipal Utility District 401" />
    <header><div className="container has-text-centered">
      <Link to="/"><img className="logo" src={logo} alt="logo" /><h1 className="title is-size-1">Customer Notification System</h1></Link>
      </div>
      </header>
    {children}
  </>
)

export default TemplateWrapper
